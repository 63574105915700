import React from "react";

const Board = ({ clickedArray, handleClick }) => {
  return (
    <div>
      {clickedArray.map((item, index) => {
        if (index === 1 || index === 4 || index === 7) {
          if (index === 1) {
            if (item === "") {
              return (
                <div
                  key={index}
                  className="ht_line"
                  onClick={() => handleClick(index)}
                ></div>
              );
            } else {
              return (
                // <div key={index} className="h_line clicked">
                <div
                  key={index}
                  className="ht_line clicked"
                  onClick={() => handleClick(index)}
                ></div>
              );
            }
          } else if (index === 4) {
            if (item === "") {
              return (
                <div
                  key={index}
                  className="hm_line"
                  onClick={() => handleClick(index)}
                ></div>
              );
            } else {
              return (
                // <div key={index} className="h_line clicked">
                <div
                  key={index}
                  className="hm_line clicked"
                  onClick={() => handleClick(index)}
                ></div>
              );
            }
          } else {
            if (item === "") {
              return (
                <div
                  key={index}
                  className="hb_line"
                  onClick={() => handleClick(index)}
                ></div>
              );
            } else {
              return (
                // <div key={index} className="h_line clicked">
                <div
                  key={index}
                  className="hb_line clicked"
                  onClick={() => handleClick(index)}
                ></div>
              );
            }
          }
        } else if (index === 0) {
          if (item === "") {
            return (
              <div
                key={index}
                className="vl_line"
                onClick={() => handleClick(index)}
              ></div>
            );
          } else {
            return (
              // <div key={index} className="h_line clicked">
              <div
                key={index}
                className="vl_line clicked"
                onClick={() => handleClick(index)}
              ></div>
            );
          }
        } else if (index === 2) {
          if (item === "") {
            return (
              <div
                key={index}
                className="vr_line"
                onClick={() => handleClick(index)}
              ></div>
            );
          } else {
            return (
              // <div key={index} className="h_line clicked">
              <div
                key={index}
                className="vr_line clicked"
                onClick={() => handleClick(index)}
              ></div>
            );
          }
        } else if (index === 3) {
          if (item === "") {
            return (
              <div
                key={index}
                className="vm_line"
                onClick={() => handleClick(index)}
              ></div>
            );
          } else {
            return (
              // <div key={index} className="h_line clicked">
              <div
                key={index}
                className="vm_line clicked"
                onClick={() => handleClick(index)}
              ></div>
            );
          }
        } else if (index === 5) {
          if (item === "") {
            return (
              <div
                key={index}
                className="vn_line"
                onClick={() => handleClick(index)}
              ></div>
            );
          } else {
            return (
              // <div key={index} className="h_line clicked">
              <div
                key={index}
                className="vn_line clicked"
                onClick={() => handleClick(index)}
              ></div>
            );
          }
        } else {
          return (
            <div
              key={index}
              className="s_line"
              onClick={() => handleClick(index)}
            >
              {" "}
            </div>
          );
        }
      })}
    </div>
  );
};

export default Board;

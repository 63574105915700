export const info = [
{ K: "1", Q: "28/01=38", S: "28/01=28", M: "", I: "" },
{ K: "2", Q: "30+59=68", S: "30+59=89", M: "", I: "" },
{ K: "3", Q: "11+98=44", S: "14+30=44", M: "2", I: "" },
{ K: "4", Q: "03+89=99", S: "00+99=99", M: "2", I: "" },
{ K: "5", Q: "85-86=28", S: "86-58=28", M: "2", I: "" },
{ K: "6", Q: "01x83=96", S: "01x83=83", M: "2", I: "" },
{ K: "7", Q: "62-46=52", S: "68-16=52", M: "2", I: "" },
{ K: "8", Q: "88-21=28", S: "89-21=68", M: "2", I: "" },
{ K: "9", Q: "60/01=18", S: "60/04=15", M: "2", I: "" },
{ K: "10", Q: "54/26=88", S: "94-26=68", M: "2", I: "" },
{ K: "11", Q: "01/42=49", S: "01+42=43", M: "2", I: "" },
{ K: "12", Q: "01+95=30", S: "01+29=30", M: "2", I: "" },
{ K: "13", Q: "26-42=30", S: "28+42=70", M: "2", I: "" },
{ K: "14", Q: "22-25=09", S: "32-23=09", M: "2", I: "" },
{ K: "15", Q: "35/18=06", S: "36/18=02", M: "2", I: "" },
{ K: "16", Q: "83/41=03", S: "93/31=03", M: "2", I: "" },
{ K: "17", Q: "98-15=73", S: "93-15=78", M: "2", I: "" },
{ K: "18", Q: "16+70=91", S: "18+73=91", M: "2", I: "" },
{ K: "19", Q: "05+68=94", S: "09+85=94", M: "2", I: "" },
{ K: "20", Q: "19+02=22", S: "19+03=22", M: "", I: "" },
{ K: "21", Q: "51+36=49", S: "81-36=45", M: "2", I: "" },
{ K: "22", Q: "62/06=36", S: "62-06=56", M: "2", I: "" },
{ K: "23", Q: "26-50=89", S: "26+58=84", M: "2", I: "" },
{ K: "24", Q: "85+16=79", S: "86-16=70", M: "2", I: "" },
{ K: "25", Q: "92+50=54", S: "92-38=54", M: "2", I: "" },
{ K: "26", Q: "08/81=03", S: "00/81=00", M: "2", I: "" },
{ K: "27", Q: "14-44=61", S: "14+47=61", M: "2", I: "" },
{ K: "28", Q: "44+13=67", S: "14+73=87", M: "2", I: "" },
{ K: "29", Q: "40-43=84", S: "40+44=84", M: "2", I: "" },
{ K: "30", Q: "20-85=46", S: "20+26=46", M: "2", I: "" },
{ K: "31", Q: "06+75=78", S: "02+76=78", M: "2", I: "" },
{ K: "32", Q: "27+27=94", S: "27+37=64", M: "2", I: "" },
{ K: "33", Q: "55-58=07", S: "55-52=03", M: "2", I: "" },
{ K: "34", Q: "70-44=38", S: "70-34=36", M: "2", I: "" },
{ K: "35", Q: "23+39=56", S: "23+39=62", M: "2", I: "" },
{ K: "36", Q: "84+17=77", S: "84-13=71", M: "2", I: "" },
{ K: "37", Q: "24+45=59", S: "24+75=99", M: "2", I: "" },
{ K: "38", Q: "48-47=05", S: "49-44=05", M: "2", I: "" },
{ K: "39", Q: "36x07=31", S: "38-07=31", M: "2", I: "" },
{ K: "40", Q: "96-21=48", S: "69-21=48", M: "2", I: "" },
{ K: "41", Q: "98-32=60", S: "96-36=60", M: "2", I: "" },
{ K: "42", Q: "90/15=23", S: "38-15=23", M: "2", I: "" },
{ K: "43", Q: "37-69=92", S: "27+65=92", M: "2", I: "" },
{ K: "44", Q: "86x19=68", S: "86-18=68", M: "2", I: "" },
{ K: "45", Q: "50/33=97", S: "90-33=57", M: "2", I: "" },
{ K: "46", Q: "79+16=54", S: "75-16=59", M: "2", I: "" },
{ K: "47", Q: "35-00=37", S: "39-02=37", M: "2", I: "" },
{ K: "48", Q: "74/09=10", S: "14x05=70", M: "2", I: "" },
{ K: "49", Q: "06x09=24", S: "08x03=24", M: "", I: "" },
{ K: "50", Q: "95-13=62", S: "99-17=82", M: "2", I: "" },
{ K: "51", Q: "63+11=68", S: "83-17=66", M: "2", I: "" },
{ K: "52", Q: "67+51=98", S: "37+61=98", M: "2", I: "" },
{ K: "53", Q: "46x01=49", S: "49x01=49", M: "", I: "" },
{ K: "54", Q: "54+52=62", S: "94-32=62", M: "2", I: "" },
{ K: "55", Q: "43/08=24", S: "48/02=24", M: "2", I: "" },
{ K: "56", Q: "01+62=39", S: "01+62=63", M: "2", I: "" },
{ K: "57", Q: "85+16=79", S: "86-16=70", M: "2", I: "" },
{ K: "58", Q: "58-54=45", S: "98-54=44", M: "2", I: "" },
{ K: "59", Q: "44/19=25", S: "44-15=29", M: "2", I: "" },
{ K: "60", Q: "46-92=26", S: "48-22=26", M: "2", I: "" },
{ K: "61", Q: "52-25=39", S: "53-23=30", M: "2", I: "" },
{ K: "62", Q: "38-82=90", S: "28+62=90", M: "2", I: "" },
{ K: "63", Q: "56-04=97", S: "56-09=47", M: "2", I: "" },
{ K: "64", Q: "04x20=98", S: "04x22=88", M: "2", I: "" },
{ K: "65", Q: "94-95=69", S: "34+55=89", M: "2", I: "" },
{ K: "66", Q: "58+05=59", S: "98-05=93", M: "2", I: "" },
{ K: "67", Q: "03/56=58", S: "03+56=59", M: "2", I: "" },
{ K: "68", Q: "35+20=71", S: "35+36=71", M: "2", I: "" },
{ K: "69", Q: "21+89=92", S: "29+63=92", M: "2", I: "" },
{ K: "70", Q: "02+97=60", S: "02+97=99", M: "2", I: "" },
{ K: "71", Q: "62+04=63", S: "62-09=53", M: "2", I: "" },
{ K: "72", Q: "59/24=46", S: "69-24=45", M: "2", I: "" },
{ K: "73", Q: "23+58=63", S: "25+38=63", M: "2", I: "" },
{ K: "74", Q: "59-74=22", S: "99-77=22", M: "2", I: "" },
{ K: "75", Q: "86-95=06", S: "96-90=06", M: "2", I: "" },
{ K: "76", Q: "11/34=49", S: "11+34=45", M: "2", I: "" },
{ K: "77", Q: "77+05=68", S: "77-09=68", M: "", I: "" },
{ K: "78", Q: "60-21=66", S: "90-21=69", M: "2", I: "" },
{ K: "79", Q: "98+01=91", S: "98-01=97", M: "", I: "" },
{ K: "80", Q: "72x05=65", S: "12x05=60", M: "2", I: "" },
{ K: "81", Q: "88/25=53", S: "88-35=53", M: "2", I: "" },
{ K: "82", Q: "91x00=09", S: "91x00=00", M: "", I: "" },
{ K: "83", Q: "91-64=14", S: "81-64=17", M: "2", I: "" },
{ K: "84", Q: "57+26=68", S: "97-29=68", M: "2", I: "" },
{ K: "85", Q: "35-26=15", S: "35-25=10", M: "2", I: "" },
{ K: "86", Q: "06/99=65", S: "06+59=65", M: "2", I: "" },
{ K: "87", Q: "97-58=69", S: "97-28=69", M: "2", I: "" },
{ K: "88", Q: "02+22=91", S: "09+22=31", M: "2", I: "" },
{ K: "89", Q: "87+13=63", S: "81-13=68", M: "2", I: "" },
{ K: "90", Q: "85+31=48", S: "66-37=49", M: "2", I: "" },
{ K: "91", Q: "47/17=03", S: "51/17=03", M: "2", I: "" },
{ K: "92", Q: "06+66=66", S: "00+66=66", M: "", I: "" },
{ K: "93", Q: "69-05=66", S: "68-05=63", M: "2", I: "" },
{ K: "94", Q: "81-54=93", S: "87-54=33", M: "", I: "" },
{ K: "95", Q: "83-01=58", S: "63-04=59", M: "2", I: "" },
{ K: "96", Q: "53+30=32", S: "62-20=32", M: "2", I: "" },
{ K: "97", Q: "62-45=41", S: "62-15=47", M: "", I: "" }
]

import React from "react";

function operation({ clickedArray, handleClick }) {
  return (
    <div>
      {clickedArray.map((item, index) => {
        if (index === 0) {
          if (item === "") {
            return (
              <div
                key={index}
                className="opl_line"
                onClick={() => handleClick(index)}
              ></div>
            );
          } else {
            return (
              <div
                key={index}
                className="opl_line clicked"
                onClick={() => handleClick(index)}
              ></div>
            );
          }
        } else if (index === 1) {
          if (item === "") {
            return (
              <div
                key={index}
                className="oql_line"
                onClick={() => handleClick(index)}
              ></div>
            );
          } else {
            return (
              <div
                key={index}
                className="oql_line clicked"
                onClick={() => handleClick(index)}
              ></div>
            );
          }
        } else if (index === 2) {
          if (item === "") {
            return (
              <div
                key={index}
                className="orl_line"
                onClick={() => handleClick(index)}
              ></div>
            );
          } else {
            return (
              <div
                key={index}
                className="orl_line clicked"
                onClick={() => handleClick(index)}
              ></div>
            );
          }
        } else if (index === 3) {
          if (item === "") {
            return (
              <div
                key={index}
                className="oll_line"
                onClick={() => handleClick(index)}
              ></div>
            );
          } else {
            return (
              <div
                key={index}
                className="oll_line clicked"
                onClick={() => handleClick(index)}
              ></div>
            );
          }
        } else if (index === 4) {
          if (item === "") {
            return (
              <div
                key={index}
                className="oel_line"
                onClick={() => handleClick(index)}
              ></div>
            );
          } else {
            return (
              <div
                key={index}
                className="oel_line clicked"
                onClick={() => handleClick(index)}
              ></div>
            );
          }
        } else {
          return (
            <div
              key={index}
              className="s_line"
              onClick={() => handleClick(index)}
            >
              {" "}
            </div>
          );
        }
      })}
    </div>
  );
}

export default operation;

import React from "react";

function operation({ clickedArray, handleClick }) {
  return (
    <div>
      {clickedArray.map((item, index) => {
        if (index === 1) {
          if (item === "") {
            return (
              <div
                key={index}
                className="eu_line"
                // onClick={() => handleClick(index)}
              ></div>
            );
          } else {
            return (
              // <div key={index} className="h_line clicked">
              <div
                key={index}
                className="eu_line clicked"
                // onClick={() => handleClick(index)}
              ></div>
            );
          }
        } else if (index === 4 ) {
            if (item === "") {
              return (
                <div
                  key={index}
                  className="ed_line"
                  onClick={() => handleClick(index)}
                ></div>
              );
            } else {
              return (
                // <div key={index} className="h_line clicked">
                <div
                  key={index}
                  className="ed_line clicked"
                  onClick={() => handleClick(index)}
                ></div>
              );
            }
          } else {
            return (
              <div
                key={index}
                className="s_line"
                onClick={() => handleClick(index)}
              >  </div>
            );
          }
      })}
    </div>
  );
}

export default operation;

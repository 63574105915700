import React, { useState, Component } from "react";
import { Modal, ModalManager, Effect } from "react-dynamic-modal";
import Board from "./Board";
import Equal from "./Equal";
import Opera from "./Opera";
import Operal from "./OperaL";
// import Check_All from "./check_all";
import { info } from "./Info";

const MAX = 97;

const N0 = ["x", "x", "x", "x", "", "x", "", "x", ""];
const NX = ["", "", "", "", "", "", "", "", ""];
const N1 = ["", "", "x", "", "", "x", "", "", ""];
const N2 = ["", "x", "x", "x", "x", "", "", "x", ""];
const N3 = ["", "x", "x", "", "x", "x", "", "x", ""];
const N4 = ["x", "", "x", "", "x", "x", "", "", ""];
const N5 = ["x", "x", "", "", "x", "x", "", "x", ""];
const N6 = ["x", "x", "", "x", "x", "x", "", "x", ""];
const N7 = ["", "x", "x", "", "", "x", "", "", ""];
const N8 = ["x", "x", "x", "x", "x", "x", "", "x", ""];
const N9 = ["x", "x", "x", "", "x", "x", "", "x", ""];
const NA = ["x", "", "x", "x", "", "x", "", "", ""];

const M = ["", "", "", "", "x", "", "", "", ""];

const O1 = ["x", "x", "", "", "", "", "", "", ""];
const O2 = ["", "x", "", "", "", "", "", "", ""];
const O3 = ["", "", "x", "x", "", "", "", "", ""];
const O4 = ["", "", "x", "", "", "", "", "", ""];
const O5 = ["", "x", "", "", "x", "", "", "", ""];

const EQ = ["", "x", "", "", "x", "", "", "", ""];
const NE = ["", "x", "", "", "", "", "", "", ""];

let G1 = "";
let G2 = "";
let G3 = "";
let G4 = "";
let G5 = "";
let G6 = "";
let GX = "";
let GY = "";

let S1 = "";
let S2 = "";
let S3 = "";
let S4 = "";
let S5 = "";
let S6 = "";
let SX = "";
let SY = "";

let T1 = "";
let T2 = "";
let T3 = "";
let T4 = "";
let T5 = "";
let T6 = "";
let TX = "";
let TY = "";

let C1 = "";
let C2 = "";
let C3 = "";
let C4 = "";
let C5 = "";
let C6 = "";
let CX = "";
let CY = "";

let LANG = "PT";
let NUME = "";
let MENS = "";
let TITLE = "";
let DESAFIO = "";
let IDENT = "";
let MODO = "";

let STICKS = "";

const IDENT_PT = "Desafio";
const IDENT_EN = "Challenge";

let num = 0;
let cond = 0;
let numx = 0;
let i = 0;
let lsx = "";

let RUNX = "";
const msg_ok = "Desafio ultrapassado";
const msg_alt = "Encontrou uma solução alternativa";
const msg_err = "Não está de acordo com o resultado pretendido";
let msg_num = "Por favor introduza um número de desafio entre 1 e ";
const msg_not = "Desafio não encontrado";

const msg_ok_en = "Challenge overcome";
const msg_alt_en = "You found a workaround";
const msg_err_en = "Not in line with the intended result";
let msg_num_en = "Please enter challenge number between 1 and ";
const msg_not_en = "Challenge not found";

const msg_last = "Atingiu o último desafio! (Selecione #0 para reiniciar)";
const msg_last_en = "Reached the last challenge! (Select #0 to restart)";

const tit_ok = "PARABÉNS!";
const tit_err = "LAMENTAMOS!";
const tit_att = "ATENÇÃO!";

const tit_ok_en = "CONGRATULATIONS!";
const tit_err_en = "SORRY!";
const tit_att_en = "WARNING!";

let TITL = "";
let SUBT = "";

let TITL1 = "";
let TITL2 = "";

const TITL1_PT = "Mude 1 stick para corrigir";
const TITL1_EN = "Change 1 stick to correct";
const TITL2_PT = "Mude 2 sticks para corrigir";
const TITL2_EN = "Change 2 sticks to correct";

const LEVEL_PT = "Nível 1";
const LEVEL_EN = "Level 1";

const GOTO_PT = "Ir para ";
const GOTO_EN = "Go to ";

let LEVEL = "";
let GOTO = "";

let BT1 = "";
let BT2 = "";
let BT3 = "";
let BT4 = "";
let BT5 = "";
let BT6 = "";
let BT9 = "";

const BT1_PT = "Repor";
const BT2_PT = "Verifica";
const BT3_PT = "Solução";
const BT4_PT = "Novo";
const BT5_PT = "Seleção";
const BT6_PT = "Aleatório";
const BT9_PT = "Modo";
const BTP_PT = "!";

const BT1_EN = "Restart";
const BT2_EN = "Check";
const BT3_EN = "Solution";
const BT4_EN = "Next";
const BT5_EN = "Select";
const BT6_EN = "Random";
const BT9_EN = "Mode";
const BTP_EN = "!";

let FLAG = "";

const FLAG_PT = "./PT.png";
const FLAG_EN = "./EN.png";

const mensStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 99999999,
    overflow: "hidden",
    perspective: 1300,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },

  content: {
    position: "relative",
    margin: "2% auto",
    width: "60%",
    // height : "100%",
    border: "1px solid rgba(0, 0, 0, .2)",
    background: "#fff",
    overflow: "auto",
    borderRadius: "4px",
    outline: "none",
    boxShadow: "0 5px 10px rgba(0, 0, 0, .3)",
  },
};

const infoStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 99999999,
    overflow: "hidden",
    perspective: 1300,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },

  content: {
    position: "relative",
    margin: "2% auto",
    width: "60%",
    height: "100%",
    border: "1px solid rgba(0, 0, 0, .2)",
    background: "#fff",
    overflow: "auto",
    borderRadius: "4px",
    outline: "none",
    boxShadow: "0 5px 10px rgba(0, 0, 0, .3)",
  },
};

function MatchStick() {
  if (RUNX === "") {
    RUNX = "X";

    msg_num = msg_num.concat(MAX);
    msg_num_en = msg_num_en.concat(MAX);

    lsx = localStorage.getItem("ms2ChallengeLang");
    if (lsx !== null && lsx !== "") {
      LANG = localStorage.getItem("ms2ChallengeLang");
    }

    lsx = localStorage.getItem("ms2ChallengeMode");
    if (lsx !== null && lsx !== "") {
      MODO = localStorage.getItem("ms2ChallengeMode");
    }

    lsx = localStorage.getItem("ms2ChallengeNumber");
    if (lsx !== null && lsx !== "") {
      if (lsx.charAt(0) === "#") {
        numx = Number(lsx.substring(1));
        if (info[numx] !== undefined) {
          if (info[numx].K !== "" && info[numx].I === "") {
            localStorage.setItem("ms2ChallengeNumber", "");
            num = numx;
            localStorage.setItem("ms2CurrentNumber", num);
            localStorage.setItem("ms2ActualNumber", num);
          }
        }
      } else {
        numx = Number(lsx);
        if (info[numx] !== undefined) {
          if (info[numx].K !== "" && info[numx].I === "") {
            num = numx;
            localStorage.setItem("ms2CurrentNumber", num);
            localStorage.setItem("ms2ChallengeNumber", "");
          }
        }
      }
    } else {
      lsx = localStorage.getItem("ms2CurrentNumber");
      if (lsx !== null && lsx !== "") {
        numx = Number(lsx);
        num = numx;
      } else {
        lsx = localStorage.getItem("ms2ActualtNumber");
        if (lsx !== null && lsx !== "") {
          numx = Number(lsx);
          num = numx;
          localStorage.setItem("ms2CurrentNumber", num);
        } else {
          num = 0;
          localStorage.setItem("ms2CurrentNumber", num);
          localStorage.setItem("ms2ActualNumber", num);
        }
      }
    }

    if (LANG === "PT") {
      TITL1 = TITL1_PT;
      TITL2 = TITL2_PT;
      IDENT = IDENT_PT;
      FLAG = FLAG_EN;
      BT1 = BT1_PT;
      BT2 = BT2_PT;
      BT3 = BT3_PT;
      BT4 = BT4_PT;
      BT5 = BT5_PT;
      BT6 = BT6_PT;
      BT9 = BT9_PT;
      LEVEL = LEVEL_PT;
      GOTO = GOTO_PT.concat(LEVEL_PT);
    } else {
      TITL1 = TITL1_EN;
      TITL2 = TITL2_EN;
      IDENT = IDENT_EN;
      FLAG = FLAG_PT;
      BT1 = BT1_EN;
      BT2 = BT2_EN;
      BT3 = BT3_EN;
      BT4 = BT4_EN;
      BT5 = BT5_EN;
      BT6 = BT6_EN;
      BT9 = BT9_EN;
      LEVEL = LEVEL_EN;
      GOTO = GOTO_EN.concat(LEVEL_EN);
    }

    STICKS = Number(info[num].M);

    if (STICKS === 0) {
      STICKS = 2;
    } else {
      STICKS *= 2;
    }

    if (info[num].M === "2") {
      TITL = TITL2;
    } else {
      TITL = TITL1;
    }

    NUME = info[num].K;

    SUBT = IDENT.concat(" #", NUME);

    G1 = info[num].Q.substring(0, 1);
    if (G1 === "0") {
      G1 = NX;
    } else if (G1 === "-") {
      G1 = M;
    } else {
      G1 = Num(info[num].Q.substring(0, 1));
    }

    G2 = Num(info[num].Q.substring(1, 2));
    GY = Ope(info[num].Q.substring(2, 3));

    G6 = info[num].Q.substring(3, 4);
    if (G6 === "0") {
      G6 = NX;
    } else {
      G6 = Num(info[num].Q.substring(3, 4));
    }

    G3 = Num(info[num].Q.substring(4, 5));

    G4 = info[num].Q.substring(6, 7);
    if (G4 === "0") {
      G4 = NX;
    } else if (G4 === "-") {
      G4 = M;
    } else {
      G4 = Num(info[num].Q.substring(6, 7));
    }

    G5 = Num(info[num].Q.substring(7, 8));

    GX = info[num].Q.substring(5, 6);
    if (GX === "=") {
      GX = EQ;
    } else {
      GX = NE;
    }

    //   // Solution

    S1 = info[num].S.substring(0, 1);
    if (S1 === "0") {
      S1 = NX;
    } else if (S1 === "-") {
      S1 = M;
    } else {
      S1 = Num(info[num].S.substring(0, 1));
    }

    S2 = Num(info[num].S.substring(1, 2));
    SY = Ope(info[num].S.substring(2, 3));

    S6 = info[num].S.substring(3, 4);
    if (S6 === "0") {
      S6 = NX;
    } else {
      S6 = Num(info[num].S.substring(3, 4));
    }

    S3 = Num(info[num].S.substring(4, 5));

    S4 = info[num].S.substring(6, 7);
    if (S4 === "0") {
      S4 = NX;
    } else if (S4 === "-") {
      S4 = M;
    } else {
      S4 = Num(info[num].S.substring(6, 7));
    }

    S5 = Num(info[num].S.substring(7, 8));

    SX = info[num].S.substring(5, 6);
    if (SX === "=") {
      SX = EQ;
    } else {
      SX = NE;
    }

    // Check_All();
  }

  const [grid1, setGrid1] = useState(G1);
  const [grid2, setGrid2] = useState(G2);
  const [grid3, setGrid3] = useState(G3);
  const [grid4, setGrid4] = useState(G4);
  const [grid5, setGrid5] = useState(G5);
  const [grid6, setGrid6] = useState(G6);
  const [gridx, setGridx] = useState(GX);
  const [gridy, setGridy] = useState(GY);

  function restart() {
    setGrid1(G1);
    setGrid2(G2);
    setGrid3(G3);
    setGrid4(G4);
    setGrid5(G5);
    setGrid6(G6);
    setGridx(GX);
    setGridy(GY);
  }

  function check() {
    T1 = concatena(grid1);
    T2 = concatena(grid2);
    T3 = concatena(grid3);
    T4 = concatena(grid4);
    T5 = concatena(grid5);
    T6 = concatena(grid6);
    TX = concatena(gridx);
    TY = concatena(gridy);
    C1 = concatena(S1);
    C2 = concatena(S2);
    C3 = concatena(S3);
    C4 = concatena(S4);
    C5 = concatena(S5);
    C6 = concatena(S6);
    CX = concatena(SX);
    CY = concatena(SY);
    if (
      T1 === C1 &&
      T2 === C2 &&
      T3 === C3 &&
      T4 === C4 &&
      T5 === C5 &&
      T6 === C6 &&
      TX === CX &&
      TY === CY
    ) {
      //   window.alert(msg_ok);
      ModalManager.open(<ModalOk ident={1} onRequestClose={() => true} />);
    } else {
      check_other();
    }
  }

  function check_other() {
    let CONT = 0;
    let CNT = 0;
    CNT = 0;
    CONT = compare(grid1, G1);
    CNT += CONT;
    CONT = compare(grid2, G2);
    CNT += CONT;
    CONT = compare(grid3, G3);
    CNT += CONT;
    CONT = compare(grid4, G4);
    CNT += CONT;
    CONT = compare(grid5, G5);
    CNT += CONT;
    CONT = compare(grid6, G6);
    CNT += CONT;
    CONT = compare(gridx, GX);
    CNT += CONT;
    CONT = compare(gridy, GY);
    CNT += CONT;

    if (CNT !== STICKS) {
      ModalManager.open(<ModalError ident={3} onRequestClose={() => true} />);
      return;
    }

    let NUM0 = 0;
    let NUM1 = 0;
    let NUM2 = 0;
    let NUM3 = 0;
    // let NUM4 = 0;
    let RES = 0;
    let OPE = "";
    let ERR = "";

    NUM1 = conv(grid2);
    NUM2 = conv(grid3);
    NUM3 = conv(grid5);
    // NUM4 = conv(grid1);

    OPE = concatena(grid1);
    if (OPE !== "111101010") {
      if (OPE === "000010000") {
        NUM1 = NUM1 * -1;
      } else {
        NUM0 = conv(grid1);
        NUM1 = NUM1 + NUM0 * 10;
      }
    }

    OPE = concatena(grid6);
    if (OPE !== "111101010") {
      if (OPE !== "000010000") {
        NUM0 = conv(grid6);
        NUM2 = NUM2 + NUM0 * 10;
      }
    }

    OPE = concatena(grid4);
    if (OPE !== "111101010") {
      if (OPE === "000010000") {
        NUM3 = NUM3 * -1;
      } else {
        NUM0 = conv(grid4);
        NUM3 = NUM3 + NUM0 * 10;
      }
    }

    OPE = concatena(gridx);
    if (OPE === "010010000") {
      OPE = concatena(gridy);
      switch (OPE) {
        case "110000000":
          RES = NUM1 + NUM2;
          break;
        case "010000000":
          RES = NUM1 - NUM2;
          break;
        case "001100000":
          RES = NUM1 * NUM2;
          break;
        case "001000000":
          if (NUM2 !== 0) {
            RES = NUM1 / NUM2;
          }
          break;
        default:
          break;
      }

      if (RES !== NUM3) {
        ERR = "X";
      }
    } else {
      if (grid4 === N0) {
        NUM3 = NUM3 * -1;
      }
      RES = NUM2 - NUM3;
      if (RES !== NUM1) {
        ERR = "X";
      }
    }

    if (ERR === "") {
      ModalManager.open(<ModalOk ident={2} onRequestClose={() => true} />);
    } else {
      ModalManager.open(<ModalError ident={1} onRequestClose={() => true} />);
    }
  }

  function solution() {
    setGrid1(S1);
    setGrid2(S2);
    setGrid3(S3);
    setGrid4(S4);
    setGrid5(S5);
    setGrid6(S6);
    setGridx(SX);
    setGridy(SY);
  }

  function select() {
    if (LANG === "PT") {
      DESAFIO = prompt(msg_num);
    } else {
      DESAFIO = prompt(msg_num_en);
    }

    if (DESAFIO !== null && DESAFIO !== "") {
      if (DESAFIO.charAt(0) === "#") {
        localStorage.setItem("ms2ChallengeNumber", DESAFIO);
        window.location.reload(false);
      } else {
        numx = Number(DESAFIO);
        if (numx > 0) {
          numx = numx - 1;
        }
        if (info[numx] !== undefined) {
          if (info[numx].K !== "" && info[numx].I === "") {
            num = numx;
            localStorage.setItem("ms2ChallengeNumber", num);
            window.location.reload(false);
          } else {
            // window.alert("Desafio não encontrado / Challenge not found");
            ModalManager.open(
              <ModalError ident={2} onRequestClose={() => true} />
            );
          }
        } else {
          ModalManager.open(
            <ModalError ident={2} onRequestClose={() => true} />
          );
        }
      }
    }
  }

  function random() {
    cond = 0;
    i = 0;
    while (cond === 0) {
      i = i + 1;
      numx = Math.random() * MAX + 0;
      numx = Math.trunc(numx);
      if (info[numx] !== undefined) {
        if (info[numx].K !== "" && info[numx].I === "") {
          num = numx;
          localStorage.setItem("ms2ChallengeNumber", num);
          window.location.reload(false);
          cond = 1;
        }
        if (i > 999) {
          cond = 1;
        }
      } else {
        if (i > 999) {
          cond = 1;
        }
      }
    }
  }

  function next() {
    lsx = localStorage.getItem("ms2ActualNumber");
    if (lsx !== null && lsx !== "") {
      numx = Number(lsx);
    }
    if (numx === MAX - 1) {
      ModalManager.open(<ModalOk ident={3} onRequestClose={() => true} />);
    } else {
      while (cond === 0) {
        i = i + 1;
        numx += 1;
        if (info[numx] !== undefined) {
          if (info[numx].K !== "" && info[numx].I === "") {
            num = numx;
            cond = 1;
          }
          if (i > 999) {
            num = 0;
            cond = 1;
          }
        } else {
          if (i > 999) {
            num = 0;
            cond = 1;
          }
        }
      }
      localStorage.setItem("ms2CurrentNumber", num);
      localStorage.setItem("ms2ActualNumber", num);
      window.location.reload(false);
    }
  }

  function language() {
    if (LANG === "PT") {
      localStorage.setItem("ms2ChallengeLang", "EN");
    } else {
      localStorage.setItem("ms2ChallengeLang", "PT");
    }
    localStorage.setItem("ms2ChallengeNumber", num);
    window.location.reload(false);
  }

  function changeMode() {
    if (MODO === "") {
      MODO = "L";
    } else {
      MODO = "";
    }
    localStorage.setItem("ms2ChallengeMode", MODO);
    localStorage.setItem("ms2ChallengeNumber", num);
    window.location.reload(false);
  }

  function infor() {
    ModalManager.open(<ModalInfo onRequestClose={() => true} />);
  }

  function policy() {
    ModalManager.open(<ModalPolicy onRequestClose={() => true} />);
  }

  function handleClick1(id) {
    setGrid1(
      grid1.map((item, index) => {
        if (index === id) {
          if (item === "") {
            item = "x";
            return item;
          } else {
            item = "";
            return item;
          }
        } else {
          return item;
        }
      })
    );
  }

  function handleClick2(id) {
    setGrid2(
      grid2.map((item, index) => {
        if (index === id) {
          if (item === "") {
            item = "x";
            return item;
          } else {
            item = "";
            return item;
          }
        } else {
          return item;
        }
      })
    );
  }

  function handleClick3(id) {
    setGrid3(
      grid3.map((item, index) => {
        if (index === id) {
          if (item === "") {
            item = "x";
            return item;
          } else {
            item = "";
            return item;
          }
        } else {
          return item;
        }
      })
    );
  }

  function handleClick4(id) {
    setGrid4(
      grid4.map((item, index) => {
        if (index === id) {
          if (item === "") {
            item = "x";
            return item;
          } else {
            item = "";
            return item;
          }
        } else {
          return item;
        }
      })
    );
  }

  function handleClick5(id) {
    setGrid5(
      grid5.map((item, index) => {
        if (index === id) {
          if (item === "") {
            item = "x";
            return item;
          } else {
            item = "";
            return item;
          }
        } else {
          return item;
        }
      })
    );
  }

  function handleClick6(id) {
    setGrid6(
      grid6.map((item, index) => {
        if (index === id) {
          if (item === "") {
            item = "x";
            return item;
          } else {
            item = "";
            return item;
          }
        } else {
          return item;
        }
      })
    );
  }

  function handleClickx(id) {
    setGridx(
      gridx.map((item, index) => {
        if (index === id) {
          if (item === "") {
            item = "x";
            return item;
          } else {
            item = "";
            return item;
          }
        } else {
          return item;
        }
      })
    );
  }

  function handleClicky(id) {
    setGridy(
      gridy.map((item, index) => {
        if (index === id) {
          if (item === "") {
            item = "x";
            return item;
          } else {
            item = "";
            return item;
          }
        } else {
          return item;
        }
      })
    );
  }

  const Header = () => {
    return (
      <div>
        <div className="row">
          <div className="col-12 text-center">
            <div className="title">{TITL}</div>
        </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <div className="sub-title">{SUBT}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a href="https://matchstick.resolution.pt/"
              // target="_blank"
              // rel="noreferrer"
              title={GOTO}
            >
              <i>{GOTO}</i>
            </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Footer = () => {
    return (
      <div className="row">
        <div className="col-12 text-center">
          <button name="B1" className="btn" onClick={restart}>
            {BT1}
          </button>
          <button name="B2" className="btn" id="check" onClick={check}>
            {BT2}
          </button>
          <button name="B3" className="btn" onClick={solution}>
            {BT3}
          </button>
          <button name="B4" className="btn" onClick={next}>
            {BT4}
          </button>
          <button name="B5" className="btn" onClick={select}>
            {BT5}
          </button>
          <button name="B6" className="btn" onClick={random}>
            {BT6}
          </button>
          <button name="B9" className="btn" onClick={changeMode}>
            {BT9}
          </button>
          <button name="B7" className="btn" onClick={language}>
            <img src={FLAG} height="20" size="20" alt=""></img>
          </button>
          <button name="B8" className="btn" onClick={infor}>
            <img src="./info.png" width="16" alt="Info" title="Info" />
          </button>
          <button name="BP" className="btn" onClick={policy}>
            <img src="./policy.png" width="16" alt="Policy/Politica" title="Policy/Politica" />
          </button>
        </div>
      </div>
    );
  };

  const Reference = () => {
    return (
      <div className="row">
        <div className="col-12 text-center">
          <p></p>
          <p>
            Powered by:&nbsp;&nbsp;
            <a
              href="https://resolution.pt/"
              target="_blank"
              rel="noreferrer"
              title="Resolution - Consultores Informáticos, Lda"
            >
              <i>Resolution - Consultores Informáticos, Lda.</i>
            </a>
          </p>
        </div>
      </div>
    );
  };

  if (MODO === "") {
    return (
      <div>
        <Header />
        <div className="row">
          <div className="col-12 text-center">&nbsp;</div>
        </div>
        <div className="board">
          <Board clickedArray={grid1} handleClick={handleClick1} />
          <Board clickedArray={grid2} handleClick={handleClick2} />
          <Opera clickedArray={gridy} handleClick={handleClicky} />
          <Board clickedArray={grid6} handleClick={handleClick6} />
          <Board clickedArray={grid3} handleClick={handleClick3} />
          <Equal clickedArray={gridx} handleClick={handleClickx} />
          <Board clickedArray={grid4} handleClick={handleClick4} />
          <Board clickedArray={grid5} handleClick={handleClick5} />
        </div>
        <Footer />
        <Reference />
      </div>
    );
  } else {
    return (
      <div>
        <Header />
        <div className="row">
          <div className="col-12 text-center">&nbsp;</div>
        </div>
        <div className="boardL">
          <Board clickedArray={grid1} handleClick={handleClick1} />
          <Board clickedArray={grid2} handleClick={handleClick2} />
          <Operal clickedArray={gridy} handleClick={handleClicky} />
          <Board clickedArray={grid6} handleClick={handleClick6} />
          <Board clickedArray={grid3} handleClick={handleClick3} />
          <Equal clickedArray={gridx} handleClick={handleClickx} />
          <Board clickedArray={grid4} handleClick={handleClick4} />
          <Board clickedArray={grid5} handleClick={handleClick5} />
        </div>
        <Footer />
        <Reference />
      </div>
    );
  }
}

export default MatchStick;

function Num(char) {
  switch (char) {
    case "0":
      return N0;
    case "1":
      return N1;
    case "2":
      return N2;
    case "3":
      return N3;
    case "4":
      return N4;
    case "5":
      return N5;
    case "6":
      return N6;
    case "7":
      return N7;
    case "8":
      return N8;
    case "9":
      return N9;
    case "A":
      return NA;
    default:
      return null;
  }
}

function Ope(char) {
  switch (char) {
    case "+":
      return O1;
    case "-":
      return O2;
    case "x":
      return O3;
    case "/":
      return O4;
    case "=":
      return O5;
    default:
      return null;
  }
}

function conv(grid) {
  let XY = concatena(grid);
  switch (XY) {
    case "111101010":
      return 0;
    case "001001000":
      return 1;
    case "011110010":
      return 2;
    case "011011010":
      return 3;
    case "101011000":
      return 4;
    case "110011010":
      return 5;
    case "110111010":
      return 6;
    case "011001000":
      return 7;
    case "111111010":
      return 8;
    case "111011010":
      return 9;
    case "101101000":
      return 11;
    default:
      return 0;
  }
}

function concatena(grid) {
  let X = "";
  let Y = "";
  // eslint-disable-next-line array-callback-return
  grid.map((item, index) => {
    if (index === 0) {
      if (item === "x") {
        X = "1";
      } else {
        X = "0";
      }
    } else {
      if (item === "x") {
        Y = "1";
      } else {
        Y = "0";
      }
      X = X.concat(Y);
    }
  });
  return X;
}

function compare(grid, init) {
  let itm = "";
  let cnt = 0;
  // eslint-disable-next-line array-callback-return
  grid.map((item, index) => {
    itm = init[index];
    if (item !== itm) {
      cnt += 1;
    }
  });
  return cnt;
}

class ModalOk extends Component {
  componentDidMount() {
    this.toggleScrollLock();
  }

  componentWillUnmount() {
    this.toggleScrollLock();
  }

  toggleScrollLock = () => {
    document.querySelector("html").classList.toggle("scroll-lock");
  };

  render() {
    const { ident, onRequestClose } = this.props;
    if (LANG === "PT") {
      TITLE = tit_ok;
    } else {
      TITLE = tit_ok_en;
    }
    if (ident === 1) {
      if (LANG === "PT") {
        MENS = msg_ok;
      } else {
        MENS = msg_ok_en;
      }
    } else if (ident === 2) {
      if (LANG === "PT") {
        MENS = msg_alt;
      } else {
        MENS = msg_alt_en;
      }
    } else {
      if (LANG === "PT") {
        MENS = msg_last;
      } else {
        MENS = msg_last_en;
      }
    }

    return (
      <Modal
        onRequestClose={onRequestClose}
        effect={Effect.Newspaper}
        style={mensStyles}
      >
        <div className="modal-content">
          <div className="modal-header">
            <div className="close" data-dismiss="modal">
              <button className="btn modal-btn-xs" onClick={ModalManager.close}>
                X
              </button>
            </div>
            <h2 className="section-heading">{TITLE}</h2>
          </div>
          <div className="modal-body text-center">
            <p className="modal_msg">{MENS}</p>
          </div>
          {/* <div className="modal-footer">
            <button className="btn modal-btn-xs" onClick={ModalManager.close}>
              X
            </button>
          </div>  */}
        </div>
      </Modal>
    );
  }
}

class ModalError extends Component {
  componentDidMount() {
    this.toggleScrollLock();
  }

  componentWillUnmount() {
    this.toggleScrollLock();
  }

  toggleScrollLock = () => {
    document.querySelector("html").classList.toggle("scroll-lock");
  };

  render() {
    const { ident, onRequestClose } = this.props;
    if (LANG === "PT") {
      TITLE = tit_err;
    } else {
      TITLE = tit_err_en;
    }
    if (ident === 1) {
      if (LANG === "PT") {
        MENS = msg_err;
      } else {
        MENS = msg_err_en;
      }
    } else if (ident === 2) {
      if (LANG === "PT") {
        MENS = msg_not;
      } else {
        MENS = msg_not_en;
      }
    } else {
      if (LANG === "PT") {
        MENS = TITL;
        TITLE = tit_att;
      } else {
        MENS = TITL;
        TITLE = tit_att_en;
      }
    }
    return (
      <Modal
        onRequestClose={onRequestClose}
        effect={Effect.SideFall}
        style={mensStyles}
      >
        <div className="modal-content">
          <div className="modal-header">
            <div className="close" data-dismiss="modal">
              <button className="btn modal-btn-xs" onClick={ModalManager.close}>
                X
              </button>
            </div>
            <h2 className="section-heading">{TITLE}</h2>
          </div>
          <div className="modal-body text-center">
            <p className="modal_msg">{MENS}</p>
          </div>
        </div>
      </Modal>
    );
  }
}

class ModalInfo extends Component {
  componentDidMount() {
    this.toggleScrollLock();
  }

  componentWillUnmount() {
    this.toggleScrollLock();
  }

  toggleScrollLock = () => {
    document.querySelector("html").classList.toggle("scroll-lock");
  };

  render() {
    const { onRequestClose } = this.props;
    let info00 = "";
    let info01 = "";
    let info02 = "";
    let info03 = "";
    let info04 = "";
    let info05 = "";
    let info06 = "";
    let info07 = "";
    let info08 = "";
    let info09 = "";
    let info10 = "";
    let info11 = "";
    let info12 = "";
    let info13 = "";
    let info14 = "";
    let info15 = "";
    let info16 = "";
    let info17 = "";
    TITLE = "INFO";
    if (LANG === "PT") {
      info00 = "Atenção! é fundamental respeitar o número de sticks a mudar.";
      info01 = "Mudar 1 stick significa desativar um e ativar outro.";
      info02 = "REPOR: Voltar à situação inicial do desafio.";
      info03 = "VERIFICA: Permite verificar se a solução está correta.";
      info04 = "SOLUÇÃO: Mostra a solução desejada.";
      info05 = "NOVO: Passa para o desafio seguinte.";
      info06 = "SELEÇÃO: Permite saltar para um determinado desafio.";
      info07 = "ALEATÓRIO: Seleciona um desafio aleatóriamente.";
      info08 = "MODO: Separa as operações para Facilitar a seleção.";
      info09 = "Pode passar para o idioma correspondente à bandeira";
      info10 = "Na coluna com = pode desativar o stick inferior para obter - .";
      info11 = "Na coluna da operação pode indicar +, -, X e / (divisão).";
      info12 = "Para a divisão, ativar apenas / no indicador X.";
      info13 =
        "Na coluna operação, pode indicar = com o stick - e com _ que está em baixo.";
      info14 =
        "Para reiniciar o contador, introduzir # e um número (ex. #0, #100) no botão SELEÇÃO.";
      info15 =
        "No caso de encontrar uma solução diferente da pretendida, pode continuar o desafio ou passar para o seguinte.";
      info16 =
        "Este passatempo está optimizado para ser usado em telemóveis na horizontal.";
      info17 = "Boa Sorte!";
    } else {
      info00 =
        "Attention! it is essential to respect the number of sticks to change.";
      info01 = "Changing 1 stick means deactivating one and activating another";
      info02 = "RESTART: Return to the initial challenge situation.";
      info03 = "CHECK: Lets you check if the solution is correct.";
      info04 = "SOLUTION: Shows the desired solution.";
      info05 = "NEXT: Move on to the next challenge.";
      info06 = "SELECT: Lets jump to a certain challenge.";
      info07 = "RANDOM: Select a challenge at random.";
      info08 = "MODE: Separate operations to improve selection.";
      info09 = "You can switch to the language corresponding to the flag";
      info10 =
        "In the column with = you can deactivate the lower stick to get - .";
      info11 =
        "In the operation column you can indicate +, -, X and / (division).";
      info12 = "For division, just activate / on the X indicator.";
      info13 =
        "In the operation column, you can indicate = with the stick - and _ which is below.";
      info14 =
        "To reset the counter, enter # and a number  (#0, #100) in the SELECT button.";
      info15 =
        "If you find a solution different than expected, you can continue the challenge or move on to the next one.";
      info16 =
        "This game/pastime is optimized to be used on mobile phones in landscape (horizontal).";
      info17 = "Good luck!";
    }
    return (
      <Modal
        onRequestClose={onRequestClose}
        effect={Effect.Sign3D}
        style={infoStyles}
      >
        <div className="modal-content">
          <div className="modal-header">
            <div className="close" data-dismiss="modal">
              <button className="btn modal-btn-xs" onClick={ModalManager.close}>
                X
              </button>
            </div>
            <h2 className="section-heading">{TITLE}</h2>
          </div>
          <div className="modal-body text-center">
            <p className="modal_info">{info00}</p>
            <p className="modal_info">{info01}</p>
            <p className="modal_info">{info02}</p>
            <p className="modal_info">{info03}</p>
            <p className="modal_info">{info04}</p>
            <p className="modal_info">{info05}</p>
            <p className="modal_info">{info06}</p>
            <p className="modal_info">{info07}</p>
            <p className="modal_info">{info08}</p>
            <p className="modal_info">{info09}</p>
            <p className="modal_info">{info10}</p>
            <p className="modal_info">{info11}</p>
            <p className="modal_info">{info12}</p>
            <p className="modal_info">{info13}</p>
            <p className="modal_info">{info14}</p>
            <p className="modal_info">{info15}</p>
            <p className="modal_info">{info16}</p>
            <p className="modal_info">{info17}</p>
          </div>
        </div>
      </Modal>
    );
  }
}


class ModalPolicy extends Component {
  componentDidMount() {
    this.toggleScrollLock();
  }

  componentWillUnmount() {
    this.toggleScrollLock();
  }

  toggleScrollLock = () => {
    document.querySelector("html").classList.toggle("scroll-lock");
  };

  render() {
    const { onRequestClose } = this.props;
    let info00 = "";
    let info01 = "";
    let info02 = "";
    let info03 = "";
    let info04 = "";
    let info05 = "";
    if (LANG === "PT") {
      TITLE = "POLITICA de PRIVACIDADE e de COOKIES";
      info00 = "POLITICA de PRIVACIDADE";
      info01 = "Matchstick não recolhe dados pessoais ou informação de qualquer outra natureza."
      info02 = "Como tal, não processa ou divulga dados e informações de forma alguma.";
      info03 = "-----";
      info04 = "POLITICA de COOKIES";
      info05 = "Matchstick não usa cookies, apenas utiliza a memória de browser para manter o status do passatempo";
    } else {
      TITLE = "PRIVACY and COOKIES POLICY";
      info00 = "PRIVACY POLICY";
      info01 = "Matchstick does not collect personal data or information of any other nature."
      info02 = "As such, it does not process or disclose data and information in any way.";
      info03 = "----";
      info04 = "COOKIES POLICY";
      info05 = "Matchstick does not use cookies, only uses browser memory to maintain the status of the contest.";
    }
    return (
      <Modal
        onRequestClose={onRequestClose}
        effect={Effect.Sign3D}
        style={infoStyles}
      >
        <div className="modal-content">
          <div className="modal-header">
            <div className="close" data-dismiss="modal">
              <button className="btn modal-btn-xs" onClick={ModalManager.close}>
                X
              </button>
            </div>
            <h2 className="section-heading">{TITLE}</h2>
          </div>
          <div className="modal-body text-center">
            <p className="modal_info">{info00}</p>
            <p className="modal_info">{info01}</p>
            <p className="modal_info">{info02}</p>
            <p className="modal_info">{info03}</p>
            <p className="modal_info">{info04}</p>
            <p className="modal_info">{info05}</p>
          </div>
        </div>
      </Modal>
    );
  }
}
